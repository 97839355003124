<template>
    <div class="date-range">
        <el-dropdown style="float: right; padding: 3px 0" @command="dateRangeCommand">
            <span class="el-dropdown-link">
                {{dropdownLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                        :command="{'label': '近一月', b_date: $moment().subtract(1, 'months').format('YYYY-MM-DD'), e_date: $moment().format('YYYY-MM-DD')}">
                    近一月
                </el-dropdown-item>
                <el-dropdown-item
                        :command="{'label': '近三月', b_date: $moment().subtract(3, 'months').format('YYYY-MM-DD'), e_date: $moment().format('YYYY-MM-DD')}">
                    近三月
                </el-dropdown-item>
                <el-dropdown-item
                        :command="{'label': '近半年', b_date: $moment().subtract(6, 'months').format('YYYY-MM-DD'), e_date: $moment().format('YYYY-MM-DD')}">
                    近半年
                </el-dropdown-item>
                <el-dropdown-item
                        :command="{'label': '近一年', b_date: $moment().subtract(12, 'months').format('YYYY-MM-DD'), e_date: $moment().format('YYYY-MM-DD')}">
                    近一年
                </el-dropdown-item>
                <el-dropdown-item
                        :command="{'label': '全周期', b_date: $moment('2020-06-01').format('YYYY-MM-DD'), e_date: $moment().format('YYYY-MM-DD')}">
                    全周期
                </el-dropdown-item>
                <el-dropdown-item :command="{'label': '自定义', b_date: '', e_date: ''}">自定义
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-dialog title="选择日期范围" width="300px" :visible.sync="dialogVisible">
            <el-date-picker type="date" placeholder="选择开始日期" v-model="startDate" format="yyyy-MM-dd"
                            style="width: 100%;"></el-date-picker>
            <el-date-picker type="date" placeholder="选择结束日期" v-model="endDate" format="yyyy-MM-dd"
                            style="width: 100%;margin-top: 20px"></el-date-picker>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="buttonOk">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

  import {formatDate} from "element-ui/lib/utils/date-util";

  export default {
    name: "DateRange",
    data () {
      return {
        dialogVisible: false,
        dropdownLabel: '',
        startDate: '',
        endDate: '',
      }
    },
    methods: {
      dateRangeCommand (command) {
        if (command.label === '自定义') {
          this.dialogVisible = true;
        } else {
          this.dropdownLabel = command.label;
          this.$store.commit('SET_DATE_RANGE', command);
          this.$emit('change');
        }
      },
      buttonOk () {
        if (this.startDate || this.endDate) {
          let b_date = formatDate(this.startDate)
          let e_date = formatDate(this.endDate)
          let command = {'label': '自定义', b_date: b_date, e_date: e_date};
          this.$store.commit('SET_DATE_RANGE', command);
          this.dropdownLabel = command.label;
          this.dialogVisible = false;
          this.$emit('change');
        }
      }
    },
    mounted () {
      this.dropdownLabel = this.$store.state.dateRange.label;
    }
  }
</script>

<style scoped>
    .el-dropdown-link {
        cursor: pointer;
        color: #FFFFFF;
    }
</style>
